const newVersion = (extraProperties) => {
	let label = '';
	if (extraProperties != null) {
		switch (extraProperties.imofalformtypeid) {
			case 1:
				label = 'reports.label.newVersion.label1';
				break;
			case 2:
				label = 'reports.label.newVersion.label2';
				break;
			case 3:
				label = 'reports.label.newVersion.label3';
				break;
			case 4:
				label = 'reports.label.newVersion.label4';
				break;
			case 5:
				label = 'reports.label.newVersion.label5';
				break;
			case 6:
				label = 'reports.label.newVersion.label6';
				break;
			case 7:
				label = 'reports.label.newVersion.label7';
				break;
			case 11:
				label = 'reports.label.newVersion.label11';
				break;
		}
	} else {
		label = 'newVersion.label';
	}

	return {
		id: 'newVersion',
		selectionType: 'single',
		label: label,
		functionality: 'NEW_VERSION',
		checkAvailability: function (registries) {
			//const registry = Array.isArray(registries) ? registries[0] : registries;
			var selected = registries[0];
			if (!selected) {
				return false;
			}

			if (selected.portcallstatus === 0 || selected.portcallstatus === 3) {
				return false;
			}
			if (selected.statusid && selected.statusid === 2) {
				// is Imo Fal Form
				return true;
			} else if (selected.docstatusid && selected.docstatusid === 2) {
				// is MDH Form or Security Form
				return true;
			} else if ((selected.statuswaste && selected.statuswaste === 2) || (selected.status && selected.status === 2)) {
				// is Waste Notification
				return true;
			}

			return false;
		},
		runAction: async function (action, model, registries) {
			let row = {
				...registries[0],
				...extraProperties
			};
			row.isAction = true;
			this.$puiEvents.$emit('pui-modalDialogForm-newVersion-' + model.name + '-show', row);
		}
	};
};

export default {
	newVersion
};

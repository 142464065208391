<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('action.newVersion')"
			:modelName="modelName"
			:dialogName="newVersion"
			:widthDialog="800"
			:onOk="onOknewVersion"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`versionRemarks`"
							v-model="data.modalData.versionRemarks"
							:label="$t('modal.version_remarks')"
							:disabled="formDisabled"
							toplabel
							required
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>
<script>
export default {
	name: 'imofalform1menu-modals',
	data() {
		return {
			newVersion: 'newVersion',
			model: this.modelName,
			versionRemarks: '',
			modalData: {}
		};
	},
	mounted() {},
	props: {
		modelName: {
			required: true,
			type: String
		},
		pkAttribute: {
			required: true,
			type: String
		}
	},
	methods: {
		onOknewVersion(modalData) {
			const controller = '/documents/newVersion';
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('action.newVersion');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					controller,
					{
						pk: modalData[this.pkAttribute],
						model: this.modelName,
						remarks: modalData.versionRemarks
					},
					() => {
						// Valid response, do code and close modal
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					null
				);
			});
		}
	}
};
</script>

<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
			<!--
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#imofalform4'">{{ $t('imofalform4.maintab') }}</v-tab>
			</v-tabs>
			-->
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'imofalform4'">
					<v-row class="pui-form-layout">
						<v-col xs12 lg12>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform4.fieldset.personalinfo')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
												<pui-text-field
													:id="`imofalform4-familyname`"
													v-model="model.familyname"
													:label="$t('imofalform4.familyname')"
													toplabel
													:disabled="formDisabled"
													required
													maxlength="70"
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
												<pui-text-field
													:id="`imofalform4-givenname`"
													v-model="model.givenname"
													:label="$t('imofalform4.givenname')"
													toplabel
													required
													:disabled="formDisabled"
													maxlength="70"
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
												<pui-select
													:id="`imofalform4-rankcode`"
													:attach="`imofalform4-rankcode`"
													:label="$t('imofalform4.rankcode')"
													:disabled="formDisabled"
													required
													toplabel
													clearable
													modelName="vlupmcrewranks"
													v-model="model"
													:itemsToSelect="rankcodeItemsToSelect"
													:modelFormMapping="{ rankcode: 'rankcode' }"
													:itemValue="['rankcode']"
													:itemText="(item) => `${item.rankdescription}`"
													:order="{ rankdescription: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform4.fieldset.effects')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-area
													:id="`imofalform4-effects`"
													v-model="model.effects"
													:label="$t('imofalform4.effects')"
													toplabel
													required
													:disabled="formDisabled"
													maxlength="512"
												></pui-text-area>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<pui-form-footer-btns
					:formDisabled="!havePermissions(['WRITE_IMOFALFORM4'])"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import imofalform4Actions from '../imofalform4menu/ImoFalForm4Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform4-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform4',
			actions: imofalform4Actions.actions,
			pkAttribute: 'imofalformid',
			parentModelName: 'imofalform4menu',
			modeldetail: true,
			parentModelPk: null,
			tabmodel: 'imofalform4',
			showParentModelHeader: false,
			labelportarrival: this.$t('imofalform4.portofarrival')
		};
	},
	computed: {
		rankcodeItemsToSelect() {
			return [{ rankcode: this.model.rankcode }];
		}
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);

			if (parentModel) {
				this.model.imofalformid = parentModel.pk.imofalformid;
				this.parentModelPk = parentModel.pk;
				this.showParentModelHeader = true;
			}
			if (this.$route.params.parentpk) {
				this.model.imofalformid = JSON.parse(atob(this.$route.params.pkgeneral)).imofalformid;
			}
		}
	}
};
</script>
